import Vue from 'vue'
import VueRouter from 'vue-router'

// Pages
import About                    from '../pages/About';
import Videos                   from '../pages/Videos';
import Video                   from '../pages/Video';

Vue.use(VueRouter)

const routes = [
    {
        path: '/about',
        name: 'about',
        component: About
    },
    {
        path: '/video/:videoID',
        props: true,
        name: 'video',
        component: Video
    },
    {
        path: '/',
        name: 'videos',
        component: Videos
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
      return {x: 0, y: 0}
  },
})

export default router
