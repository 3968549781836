<template>
    <div class="coming-soon">
        -- Coming Soon! --
    </div>
</template>

<script>

export default {
  name: 'ComingSoon',
  props: {
    videoID: {
        type: String,
        required: true,
    }
  },
  data () {
      return {
        
      }
  }

}
</script>

<style scoped>
.coming-soon {
    width: 100%;
    font-size: 3em;
    text-align: center;
}
</style>
