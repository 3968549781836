import Vue from 'vue'
import Vuex from 'vuex'
import video from './modules/video';
import organization from './modules/organization';
import siteInfo from './modules/siteInfo';
import constants from './modules/constants';
import utils from './modules/utils';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
      video,
      organization,
      siteInfo,
      constants,
      utils,
    }
})
