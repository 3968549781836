const state = {
    componentKey: 0
}

const mutations = {
    'INCREMENT_COMPONENT_KEY' (state) {
        state.componentKey++;
    }
}

const actions = {
    forceRender({commit}) {
        commit('INCREMENT_COMPONENT_KEY');
    }
}

const getters = {
    componentKey: state => {
        return state.componentKey;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}