import sysOutDev from '../../data/sysOut';

let siteInfo

if (process.env.NODE_ENV === 'development') {
    siteInfo = sysOutDev.siteInfo;
} else {
    siteInfo = window.sysOut.siteInfo;
}

const state = {
    siteInfo: siteInfo
}

const mutations = {
}

const actions = {
}

const getters = {
    siteInfo: state => {
        return state.siteInfo;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}